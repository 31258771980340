import React from 'react';

import { getYearsSinceOfficeBirth } from '../../../utils/utils';
import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const Construction = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.CONSTRUCTION}>
      <h1>Construction</h1>

      {genererFilAriane('construction')}
      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="construction"
          src="/img/competences_sprite.jpg"
          alt="Construction"
        />
      </div>

      <p>
        Pendant{' '}
        <span className="accent">
          <span id="anneesExp">{getYearsSinceOfficeBirth()}</span> ans
        </span>
        , notre firme a représenté les intervenants dans le domaine de la
        construction :{' '}
        <span className="accent2">
          propriétaire, donneurs d'ouvrage, entrepreneurs, sous-entrepreneurs
        </span>
        , etc. Notre expérience touche tout type de projet de construction
        résidentiel et commercial. Nous vous aiderons notamment en matière de{' '}
        <span className="accent">vices cachés</span>, d’
        <span className="accent">hypothèque légale de la construction</span> et
        de <span className="accent">cautionnement</span>.
      </p>
    </CompetencesContainer>
  );
};

Construction.propTypes = {};

export default Construction;
