import React from 'react';

import { PAGES } from '../../utils/utils';
import Construction from '../../components/fr/competences/construction';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ConstructionPage = () => (
  <Layout page={PAGES.COMPETENCES_CONSTRUCTION}>
    <SEO title="Construction" />
    <Construction />
  </Layout>
);

export default ConstructionPage;
